import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Products from "../components/product-list/products";
import CategoriesFilter from "../components/CategoryFilter";
import PaginationTwo from "../components/PaginationTwo";
// import ProductsStatic from "../components/products-static";

export default function WomanPage({ data, pageContext }) {
  const list = [
    {
      id: "1a",
      media: "t_shirt_1.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-30",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1b",
      media: "t_shirt_2.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-31",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1c",
      media: "t_shirt_3.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-32",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1d",
      media: "t_shirt_4.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-34",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1e",
      media: "t_shirt_5.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-35",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1f",
      media: "t_shirt_6.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-36",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1g",
      media: "t_shirt_7.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-37",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1h",
      media: "t_shirt_8.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-38",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1i",
      media: "t_shirt_9.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-39",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1j",
      media: "t_shirt_10.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-40",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1k",
      media: "t_shirt_13.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-41",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1l",
      media: "t_shirt_14.jpeg",
      name: "Футболка",
      slug: "woman/futbolka-42",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1l",
      media: "mayki.jpeg",
      name: "Майки и лямки",
      slug: "woman/futbolka-43",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Майки и лямки" },
      ],
    },
    {
      id: "1m",
      media: "sk_1.jpeg",
      name: "Спортивный костюм",
      slug: "woman/futbolka-43",
      category: [
        {
          id: "-0210e194-5108-5a37-ad32-3914d3185069",
          name: "Спортивные костюмы",
        },
      ],
    },
    {
      id: "1n",
      media: "sk_2.jpeg",
      name: "Спортивный костюм",
      slug: "woman/futbolka-43",
      category: [
        {
          id: "-0210e194-5108-5a37-ad32-3914d3185069",
          name: "Спортивные костюмы",
        },
      ],
    },
    {
      id: "1o",
      media: "sk_3.jpeg",
      name: "Спортивный костюм",
      slug: "woman/futbolka-43",
      category: [
        {
          id: "-0210e194-5108-5a37-ad32-3914d3185069",
          name: "Спортивные костюмы",
        },
      ],
    },
    {
      id: "1p",
      media: "sk_4.jpeg",
      name: "Спортивный костюм",
      slug: "woman/futbolka-43",
      category: [
        {
          id: "-0210e194-5108-5a37-ad32-3914d3185069",
          name: "Спортивные костюмы",
        },
      ],
    },
    {
      id: "1q",
      media: "sk_5.jpeg",
      name: "Спортивный костюм",
      slug: "woman/futbolka-43",
      category: [
        {
          id: "-0210e194-5108-5a37-ad32-3914d3185069",
          name: "Спортивные костюмы",
        },
      ],
    },
  ];

  return (
    <Layout
      title="Женская одежда"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-5 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
          <CategoriesFilter activeCategory={pageContext.category} />
          {/* <ProductsStatic data={list} /> */}
          <Products data={data.womans} />
          <PaginationTwo
            pageSize={8}
            totalCount={data.womans.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base="/woman"
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 8) {
    womans: allSanityWoman(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
